body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

code {
  font-family: "Inter", sans-serif;
}

.navigation-closed {
  background-color: #FFFFFF;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
  border-top: 1px solid #E4E4E4;
  padding: 50px 80px;
  position: fixed;
  z-index: 2;
  width: auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  display: none;
  margin-left: 257px;
}

.navigation {
  background-color: #FFFFFF;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
  border-top: 1px solid #E4E4E4;
  padding: 50px 80px;
  position: fixed;
  z-index: 2;
  width: auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  display: flex;
  margin-left: 257px;
}

.overlay:after,
.open:after {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  content: "";
  z-index: 1;
}

.open .navigation,
.open:after {
  display: flex;
}

.navigation ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.navigation ul li {
  margin-right: 50px;
}

.navigation ul li a {
  color: #161616;
  font-size: 18px;
  text-decoration: none;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.navigation ul li a em {
  margin-right: 6px;
  position: relative;
  top: 2px;
}

.dashboard {
  padding-left: 280px;
  padding-right: 25px;
  padding-top: 25px;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  opacity: 0.1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);

  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  opacity: 0.1;
}

.App {
  text-align: center;
}

.btn-default {
  color: #0D49A0;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 35px;
  position: relative;
  border: 1px solid #0D49A0;
  background-color: #E4EEFF;
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: #61dafb;
}

nav ul li a:hover {
  text-decoration: underline;
}

.scrollbar {
  max-height: 600px;
  overflow-y: auto;
  margin-right: -30px;
  padding-right: 30px;
}

.logo {
  background: #202020;
  height: 70px;
  width: 256px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  font-size: 1.3em;
  text-align: center;
}

.logo img {
  width: 65%;
}

.expand-search {
  display: none;
}

.selectbox.active .expand-search {
  display: block;
}

/* Header */
.header {
  background-color: #fff;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding-left: 280px;
  align-items: center;
  background-color: #fff;
  padding-right: 25px;
  position: relative;
  z-index: 2;
}

.header-right {
  display: flex;
  align-items: center;
}

.searchbox {
  position: relative;
}

.searchbox input {
  border: 0;
  outline: 0;
  height: 36px;
  width: 240px;
  color: #A4ACB4;
  background-color: #F6F6F6;
  border-radius: 50px;
  padding: 0 10px 0 38px;
}

.header-right em {
  margin-left: 15px;
  cursor: pointer;
}

.searchbox em {
  position: absolute;
  top: 10px;
  left: 12px;
}

.dashboard-right {

  height: 100%;
  position: relative;

}

.dashboard .next-btn {
  background: rgb(34, 148, 254);
  background: linear-gradient(180deg, rgba(4, 99, 211, 1) 0%, rgba(3, 73, 160, 1) 100%);
  height: 49px;
  border-radius: 80px;
  padding: 0 20px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  text-transform: none;
  width: 121px;
}

.dashboard .back-btn {
  background: #F4F4F5;
  height: 49px;
  border-radius: 80px;
  padding: 0 20px;
  font-size: 15px;
  color: #161616;
  font-weight: 500;
  text-transform: none;
  width: 121px;
}

.dashboard .back-btn svg {
  width: 16px;
  margin-right: 5px;
}

.dashboard .next-btn svg {
  width: 16px;
  margin-left: 5px;
}


.content {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.right-panel {
  background-color: #F4F8FB;
  text-align: left;
  padding: 30px 40px 40px;
  border-radius: 20px;
  margin-left: 15px;
}

.btnblock {
  width: calc(100% - 33%);
  margin: 15px 0px;
}

.btnblock button {
  background-image: linear-gradient(180deg, #1863d3 0%, #0D49A0 100%);
  border-radius: 80px;
  text-transform: none;
  box-shadow: none;
  padding: 12px 30px;
}

.btnblock button:disabled {
  background-image: none;
  background-color: #f4f4f5;
}

.btnblock button>span>svg {
  font-size: 15px !important;
}

button.crowdwave-blue {
  background-color: #0D49A0;
  border-radius: 8px;
  box-shadow: none;
}

button.crowdwave-blue-light {
  background-color: #E4EEFF;
  border-radius: 8px;
  box-shadow: none;
  color: #0D49A0;
  border: 1px solid #0D49A0;
}

button.crowdwave-blue-light:hover {
  background-color: #eff5ff;
  border-radius: 8px;
  color: #0D49A0;
  border: 1px solid #0D49A0;
}

.left-panel {
  padding: 30px;
  text-align: left;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #E4E4E4;
}

h3 {
  font-size: 18px;
  color: #4D5B68;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 32px;
  color: #161616;
  font-weight: 600;
  margin: 11px 0 0;
}

p {
  font-size: 14px;
  list-style: 20px;
  color: #5B6066;
}

.add-more {
  color: #0D49A0;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  padding-left: 20px;
  position: relative;
  border: 1px solid #0D49A0;
  background-color: #E4EEFF;
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;
}

.add-more span {
  display: inline-block;
  padding-left: 1px;
  font-style: italic;
}

.copy-snackbar,
.copy-snackbar div {
  background-color: green;
}

.label,
div.label {
  font-size: 14px;
  color: #161616;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.d-flex.label {
  display: flex;
  justify-content: space-between;
}

.label em,
em.info {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  color: #fff;
  font-style: normal;
  background-color: #B0B0B0;
  display: inline-flex;
  justify-content: center;
  font-size: 9px;
  margin-left: 5px;
}

.rankings {
  display: block;
  font-size: 14px;
  color: #161616;
  font-weight: 500;
  width: 72.5%;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.rankings em {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  color: #fff;
  font-style: normal;
  display: inline-block;
  background-color: #B0B0B0;
  display: inline-flex;
  justify-content: center;
  font-size: 9px;
  margin-left: 5px;
}

.rankings span:nth-child(2) {
  margin-left: 90px;
}

.rankings-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.rankings-box button:nth-child(1) {
  border-radius: 5px;
  border: 1px solid #B0B0B0;
  padding: 0 12px;
  height: 48px;
  font-size: 20px;
  color: #B0B0B0;
  background-color: #FFFFFF;
  width: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rankings-box input:nth-child(2) {
  margin-left: 15px;
  color: #333333;
}

.rankings-button {
  border-radius: 5px;
  border: 1px solid #B0B0B0;
  padding: 0 12px;
  height: 48px;
  font-size: 32px;
  color: #0D49A0;
  /*white background*/
  background-color: #FFFFFF;
  width: 125px;
  cursor: pointer;
}

.rankings-button:hover {
  background-color: #E4EEFF;
}


.left-panel input {
  border-radius: 5px;
  border: 1px solid #B0B0B0;
  padding: 0 12px;
  height: 48px;
  font-size: 16px;
  color: #9F9F9F;
  width: 100%;
  box-sizing: border-box;
}

.textField-box {
  margin-bottom: 15px;
}

.textBox {
  /* display: flex;
  align-items: center;
  flex-wrap: wrap; */
  width: 80%;

}

.report-view {
  margin-top: 0px;
  margin-bottom: 20px;
}

.right-panel ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.right-panel ul li {
  border: 1px solid #E4E4E4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  padding: 15px;

}

.right-panel ul li a {
  font-size: 16px;
  color: #5B6066;
  text-decoration: none;
  font-weight: 400;

}

.right-panel h4 {
  font-size: 20px;
  color: #4D5B68;
  font-weight: 500;
  margin-bottom: 12px;
}

.right-panel ul li a em {
  position: absolute;
  top: 42%;
  right: 15px;
  opacity: 0;
}

.right-panel ul li:hover em {
  opacity: 1;
}

.content-top {
  margin-top: 40px;
}

.right-panel .MuiAccordion-rounded {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  margin: 0 !important;
}

.right-panel .MuiAccordion-rounded::before {
  display: none;
}

.right-panel .MuiAccordionSummary-content {
  margin: 0 !important;
}

.right-panel .MuiAccordionDetails-root {
  padding: 0;
}

.right-panel .MuiAccordionSummary-gutters {
  padding: 0;
  min-height: initial !important;
}

.bottom-block {
  padding: 25px;
  position: fixed;
  bottom: 0;
  width: 206px;
}

.bottom-block ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bottom-block ul li {
  padding: 20px 0;
  border-bottom: 1px solid #1B1B1B;
  text-align: left;
}

.bottom-block ul li:last-child {
  border-bottom: 0;
}

.bottom-block ul li a {
  font-size: 14px;
  color: #A4ACB4;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.bottom-block ul li a em {
  margin-right: 25px;
  position: relative;
  top: 2px;
}

.expand-block {
  width: 28px;
  height: 28px;
  background: #4D4D4D;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.segments-box {
  display: flex;
}

.segments-box {
  width: 80%;
  margin-bottom: 20px;
}

.segments-box input:nth-child(2) {
  width: 140px;
  margin-left: 15px;
}

.segments-box button:last-child {
  width: 140px;
  margin-left: 15px;
}

.segments-view {
  border-top: 0;
}

.segments-view ul {
  display: flex;
}

.segments-view ul li span {
  display: block;
}

.segments-view ul li {
  flex-wrap: wrap;
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  margin-right: 10px;
  width: 80%;
  color: #5B6066;
  display: flex;
  align-items: center;
}

.segments-view ul li span {
  font-size: 14px;
  color: #5B6066;
  font-weight: 500;
  margin-bottom: 15px;
}

.segments-view ul li:last-child {
  margin-right: 0;
  width: 80px;
  text-align: center;
}

.improve-btn {
  border-radius: 8px;
  padding: 0 15px;
  font-size: 16px;
  color: #0D49A0;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 32px;
  border: 1px solid #0D49A0;
  width: 148px;
  margin-top: 50px;
}

.improve-btn em {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.no-data-wrapper {
  display: none;
}

.no-data-wrapper h3 {
  text-transform: none;
  font-size: 20px;
  font-weight: 400;
  color: #A4A4A4;
  opacity: 1;
  text-align: center;
}

.no-data-wrapper em {
  margin: 16% 0;
  display: flex;
  align-items: center;
}

.step-sec .segments-box {
  width: 100%;
}

.step-sec .add-more {
  width: 220px;

}

.heading {
  font-size: 12px;
  font-weight: 400;
  color: #5B6066;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;


}

.heading span {
  width: 80%;
  padding-right: 23px;
}

.heading span:last-child {
  width: 80px;
}

textarea {
  border: 1px solid #E4E4E4;
  padding: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 150px;
  width: 100%;
  resize: none;
  font-family: "Inter", sans-serif;
}

/* Step 3 */
.question-bontent {
  position: relative;
}

.question-bontent .improve-btn {
  position: absolute;
  right: 15px;
  bottom: 18px;
  margin-top: 0;
  cursor: pointer;
}

.upload-btn {
  display: flex;
  align-items: center;
  height: 36px;
  width: 165px;
  padding-left: 15px;
}

.upload-btn em {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.upload-block {
  border: 1px dashed #E4E4E4;
  height: 84px;
  font-size: 14px;
  color: #5B6066;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  position: relative;
  display: none;
}

.my-component {
  margin: 0px 0 40px
}

.my-component.active .upload-btn {
  display: none;
}

.my-component.active .upload-block {
  display: flex;
}


.upload-block .MuiButton-colorPrimary {
  background: none !important;
  box-shadow: none !important;
  color: #0D49A0;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  padding-right: 6px;
}

.upload-block .MuiButton-colorPrimary em {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.close-icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.selectbox .MuiFormControl-root {
  width: 100%;
  margin: 8px 0;
  height: 48px;

}

.selectbox .MuiSelect-select {
  padding: 12.5px 14px;
  color: #545454;
  font-size: 15px;
}

.selectbox .MuiInputBase-root {
  border-radius: 8px;
}

.selectbox .add-more {
  width: 140px;
}

.q2 {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 15px;
}

.q2 ul li {
  border: 0;
  padding: 0;
  font-size: 14px;
  color: #5B6066;


}

.rating {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #4D5B68;
  opacity: 0.3;
}

.rating span:last-child {
  text-align: right;
}

.q3 ul {
  flex-wrap: wrap;
}

.q3 ul li {
  margin-right: 20px;
  width: auto;
}

.q-block p {
  font-size: 16px;
  color: #545454;
  line-height: 24px;
}

/* Sidebar */
.stepper-sidebar .MuiStepper-horizontal {
  display: block;
  margin: 25px;
  position: relative;
  height: calc(100% - (20px + 30px));
}

.stepper-sidebar {
  background: #2C2B2B;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 256px;
  z-index: 2;
}

.stepper-sidebar .MuiStepLabel-label {
  color: #A4ACB4;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  text-align: left;
  display: flex;
  align-items: center;

}



.stepper-sidebar .MuiStepLabel-label.Mui-active {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}

.stepper-sidebar .MuiStepLabel-label em {
  width: 31px;
  height: 31px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  position: relative;
  top: -1px;
}

.stepper-sidebar .MuiStepLabel-label.Mui-active em {
  background: rgb(34, 148, 254);
  background: linear-gradient(180deg, rgba(34, 148, 254, 1) 0%, rgba(34, 71, 254, 1) 100%);
  justify-content: center;
}

.stepper-sidebar .MuiStepLabel-label.Mui-active img {
  filter: brightness(0) invert(1);
}

.stepper-sidebar .MuiStepLabel-label.Mui-completed {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.stepper-sidebar .css-vnkopk-MuiStepLabel-iconContainer {
  float: right;
  order: 1;
  z-index: 1;
  position: relative;
}

.stepper-sidebar .MuiStep-horizontal {
  padding: 20px 0;
  border-bottom: 1px solid #1B1B1B;
  position: relative;
}

.stepper-sidebar .MuiSvgIcon-fontSizeMedium {
  color: #5B5B5B;
  width: 12px;
  height: 12px;
}

.stepper-sidebar .MuiSvgIcon-fontSizeMedium.Mui-active {
  color: #50C878;
  box-shadow: 0 2px 10px rgba(80, 200, 120, 0.4);
  border-radius: 50px;
}

.stepper-sidebar .MuiSvgIcon-fontSizeMedium.Mui-completed {
  border-radius: 50px;
  color: #2C2B2B;
  background: #50C878;
  width: 24px;
  height: 25px;
}

.stepper-sidebar .css-vnkopk-MuiStepLabel-iconContainer:after {
  background-color: transparent;
  width: 17px;
  height: 12px;
  position: absolute;
  content: "";
  left: -4px;
  top: 0;
  z-index: 1;
  border: 8px solid #2C2B2B;
  display: none;

}

.stepper-sidebar .css-vnkopk-MuiStepLabel-iconContainer.Mui-completed {
  left: 6px;
}

.stepper-sidebar .css-vnkopk-MuiStepLabel-iconContainer.Mui-completed:after {
  display: block;
}



.stepper-sidebar .MuiStepConnector-horizontal.Mui-active .MuiStepConnector-line {
  border-color: #50C878;
  ;
}

.stepper-sidebar.MuiStepConnector-horizontal.Mui-completed .MuiStepConnector-lineHorizontal {
  border-color: #5B5B5B;
}


.stepper-sidebar .MuiStepIcon-text {
  display: none;
}

.stepper-sidebar .MuiStepConnector-horizontal {
  position: absolute;
  transform: rotate(90deg);
  right: -64px;
  height: 50px;
  width: 100%;
  top: 120px;
}

.stepper-sidebar .MuiStepConnector-line {
  border-color: #5B5B5B;
}

/* .left-panel,
.right-panel {
  max-height: 100%;
  overflow-y: auto;

} */

.content-top {
  max-height: 467px;
}

.review-block {
  background-color: #F4F8FB;
  padding: 25px;
  border-radius: 20px;
  margin-bottom: 40px;
  margin-top: 50px;
}

.review-col {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.review-col h4 {
  font-size: 24px;
  font-weight: 600;
  color: #161616;
  margin: 0;
}

.review-col a {
  font-size: 16px;
  font-weight: 600;
  color: #0D49A0;
  text-decoration: none;
}

.review-col span {
  font-size: 16px;
  color: #5B6066;
}

.col-3 span,
.col-3 h4,
.col-3 a {
  width: 33.33%;
}

.review-col h4:first-child,
.review-col span:first-child {
  width: 45%;
}

.review-col.last-child {
  margin-top: 50px;
  margin-bottom: 30px;
}

.col-3 a {
  text-align: right;
}

.no-survey {
  text-align: center;
}

.no-survey h2 {
  font-weight: 700;
  margin-top: 3%;
  line-height: 42px;
}

.no-survey span {
  display: block;
  font-weight: 500;
}

/* User Survey */
.user-surveys-table {}

.user-surveys-table td.status span {
  background-color: rgba(253, 152, 31, 0.1);
  font-size: 12px;
  color: #FD981F;
  font-weight: 600;
  border-radius: 30px;
  padding: 5px 8px 5px 8px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 7px;
}

.user-surveys-table td.status span.complete {
  background-color: rgba(80, 200, 120, 0.1);
  color: #50C878;
}

.user-surveys-table td.status span.error {
  background-color: rgba(255, 236, 235, 1);
  color: #FF3B30;
}

.user-surveys-table td {
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #E4E4E4;
  padding: 15px;
}

.user-surveys-table th {
  background-color: #F8F9FA;
  height: 45px;
  font-size: 17px;
  color: rgba(77, 91, 104, 0.5);
  font-weight: 600;
  text-transform: uppercase;
  width: 20%;
  padding: 0 15px;
}

.user-surveys-table .view-results {
  height: 36px;
  padding: 0 15px;
  border-radius: 10px;
}

.user-surveys-table .view-results.active {
  background-color: #E4EEFF;
  color: #0D49A0;
  border: 1px solid #0D49A0;
  cursor: pointer;
}

.user-surveys-table .download {
  display: flex;
  color: #A4ACB4;
  margin-top: 8px;
  font-weight: 400;
  align-items: center;
  cursor: pointer;
}

.user-surveys-table .download.active {
  color: #0D49A0;
}


.user-surveys-table .download em {
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.results-col ul {
  list-style: none;
  margin: 0 -10px;
  padding: 0;
  display: flex;

}

.results-col ul li {
  background-color: #fff;
  padding: 20px;
  font-size: 14px;
  color: #6C737F;
  display: inline-grid;
  align-items: center;
  margin: 0 10px;
  width: 25%;
}

.results-row h4 {
  font-size: 24px;
  margin: 15px 0;
  font-weight: 500;
}

.result-header {
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
  justify-content: end;
}

.result-header a {
  color: #0D49A0;
  text-decoration: none;
  cursor: pointer;
}

.result-header a:nth-child(2) {
  padding-right: 20px;
}

.result-header a em {
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.result-header .tabs {
  font-size: 14px;
  color: #0D49A0;
  border-bottom: 2px solid #0D49A0;
  text-transform: none;
  border-radius: 0;
  padding: 0;
}

.results-row {
  margin-top: 30px;
}

.chart-block {
  width: 100%;
  height: 300px;
  background-color: #fff;
  margin-top: 20px;
}

/* @media only screen and (max-width: 1732px) {

  .btnblock {
    width: calc(100% - 42%);
  }
}


@media only screen and (max-width: 1440px) {
  .btnblock {
    width: calc(100% - 42%);
  }
} */


.chart-container {
  box-sizing: border-box;
  display: block;
  height: 300px;
  width: 600px;
  margin-left: 25px;
}